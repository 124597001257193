/**
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Custom CSS */

body {
  font-family: "Open Sans", sans-serif;
  background-color: #fff !important;
}

.App {
  height: 100%;
  background-color: #fff;
}

.full-height-container {
  height: calc(100% - 30px);
}

.body-container {
  height: 100%;
}

.navbar-custom {
  background-color: #fff;
  font-weight: 700;
  border: none;
  position: fixed;
  height: 80px;
  /* padding-top: 15px;
  padding-bottom: 15px; */
  top: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  overflow: visible;
}

.navbar-item {
  color: #064b8d !important;
  text-transform: none !important;
}

.navbar-logo {
  height: 50px;
}

.has-navbar {
  padding-top: 100px;
}

.dashboard-container {
  min-height: 80%;
}

.navbar-custom .navbar-brand .title {
  font-size: 1.5em;
}

ul.cards {
  margin-top: 20px;
}

li.card-inline {
  display: inline-block;
  width: 300px;
  height: 326px;
  margin: 5px;
  text-align: center;
}

img.card-image {
  width: 150px;
  padding-top: 20px;
}

.user-details {
  margin-bottom: 40px;
}

.hero {
  text-align: center;
  background: #18bc9c;
  padding: 20px;
  color: #fff;
}

img.employee-image {
  width: 350px;
  margin: 10px 0 20px;
}

td.title {
  text-align: right;
  font-weight: 700;
}

.callout {
  padding: 20px;
  margin: 20px auto;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  width: 1000px;
  border-left-color: #ce4844;
}

.callout h4 {
  color: #ce4844;
  margin-top: 0;
  margin-bottom: 5px;
}

.details-table,
.reports {
  width: 1000px;
  margin: 0 auto;
}

.reports {
  margin-top: 30px;
}

img.report-img {
  width: 100px;
  padding-right: 10px;
}

.report-desc {
  width: 230px;
}

.report-desc p {
  margin: 0;
  color: gray;
}

.direct-report {
  display: inline-block;
}

.direct-report .card {
  width: 352px;
  margin: 5px;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hint {
  color: gray;
  font-size: 11px;
  display: flex;
  margin-top: 0.5rem;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.container.form {
  padding-top: 2rem;
  flex-direction: column;
}

.buttons-row {
  flex-direction: row;
}

.flexible-button {
  display: flex;
  flex-wrap: wrap;
}
.flexible-button.right {
  margin-left: 1rem;
}

.popup {
  padding: 2rem;
  text-align: left;
}

.popup.alert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.centered {
  text-align: center !important;
}

.centered-container {
  align-items: center;
  justify-content: center;
}

.tall-container {
  height: 70vh !important;
}

.stretch {
  width: 100%;
  display: flex;
}
.column {
  flex-direction: column;
}
.header {
  margin-top: 2rem;
}

.doc-icon {
  float: left;
  margin-right: 0.5rem;
}

html,
body,
#root {
  height: 100%;
}

.login-container {
  height: 90%;
  display: table;
}

.fading-label {
  position: absolute;
}

.fading-label-background {
  opacity: 0;
}

.login-background {
  width: 100%;
  height: 100%;
  background-color: #fafbff;
}

.router-container {
  height: 100%;
}

.navbar-floating-menu {
  position: absolute;
  top: 36;
  right: 0;
  left: 0;
}

.help-content {
  margin-top: 2rem;
  padding-left: 2rem;
}

.help-row {
  display: list-item;
  margin-top: 1rem;
  line-height: 1.5rem;
}

.question-buttons {
  position: fixed;
  bottom: 6rem;
}

.question-buttons-wrapper {
  padding-top: 1rem;
}

.has-footer {
  padding-bottom: 7rem;
}

.menu-item-destructive {
  color: #f50057 !important;
}

.has-sidebar {
  margin-left: 15.5rem !important;
}

.sidebar {
  margin-top: 0px !important;
  position: fixed;
  padding-bottom: 180px !important;
  max-height: 100%;
  width: 15rem;
}

.questions-sidebar {
  margin: 0 !important;
  margin-right: 1.5rem !important;
  border-right: lightgray !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
  max-height: 65vh;
}

.questions-sidebar-label {
  font-size: 0.9rem;
  color: #7d8185;
  font-weight: normal;
}

.questions-sidebar-label.selected {
  color: #064b8d;
  font-weight: 500;
}

.questions-sidebar-label.complete {
  color: #064b8d;
  font-weight: 500;
}

.questions-sidebar-label:hover {
  color: #064b8d;
  font-weight: 500;
}

.MuiGridListTile-root {
  padding: 0 !important;
}

.questions-sidebar-wrapper {
  cursor: pointer;
  display: inline-block;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-radius: 10px;
}

.questions-sidebar-row {
  display: inline-flex !important;
}

.questions-sidebar-wrapper:hover {
  background-color: #f0f4f9;
}

.questions-sidebar-wrapper.selected {
  background-color: rgba(0, 76, 148, 0.06);
}

.questions-sidebar-icon {
  font-size: 1rem !important;
  margin-right: 10px !important;
  color: #7d8185 !important;
}

.questions-sidebar-icon.complete {
  color: #004c94 !important;
}

.question-label {
  color: #064b8d;
}

.dashboard-logo {
  width: 100%;
}

.card-button {
  padding: 0px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.language-button {
  font-size: 0.8rem !important;
  font-weight: 100 !important;
  min-width: 0px !important;
  padding: 0 3px 0 3px !important;
}
.language-button.selected {
  font-weight: bold !important;
}

.language-divider {
  background-color: #064b8d;
  width: 1px;
  height: 20px;
  margin-left: 7px;
  margin-right: 7px;
}

.field-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  padding: 0;
  font-weight: 400;
  line-height: 0.75;
  letter-spacing: 0.00938em;
}

.edit-image {
  display: inline-flex;
  position: relative;
  left: 5px;
}

.logo-container {
  text-align: end;
}

.logo {
  display: block;
  width: 100%;
  height: auto;
  max-height: 120px;
  object-fit: contain;
  margin-top: -40px;
}

.flag-img {
  width: 60px;
  height: auto;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiLinearProgress-colorPrimary.green {
  background-color: #e9e7e7 !important;
}
.green > .MuiLinearProgress-barColorPrimary {
  background-color: #08b278 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgba(0, 76, 148, 0.06) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #004c94 !important;
}

.group-title {
  font-size: 1.5rem;
  color: #064b8d;
  font-weight: bold;
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.icon-button-left {
  padding: 0 !important;
  margin-right: 12px !important;
}

.legal-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 4rem;
  padding: 5px;
  background-color: #f6f6f6;
}

.legal-link {
  font-size: 12px;
  color: #8b8b8b;
}

.legal-link:hover {
  color: darkgray;
  text-decoration: none;
}

.legal-divider {
  height: 15px;
  width: 1px;
  background-color: #8b8b8b;
  margin-left: 10px;
  margin-right: 10px;
}

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 200;
}

.custom-button {
  text-transform: none !important;
  font-size: 13px !important;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.MuiButton-root {
  text-transform: none !important;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.plain-button {
  background-color: transparent;
  text-transform: none !important;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.clean-button:hover {
  -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.plain-button.primary {
  color: #004c94;
}

.plain-button.small {
  font-size: 11px;
  font-weight: 400;
}

.custom-button.green {
  background-color: #0ec788 !important;
  color: white !important;
  /* text-transform: none !important; */
}
.custom-button.green:hover {
  -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.custom-button.secondary {
  background-color: #fcf2e9 !important;
  color: #ea862c !important;
}

.custom-button.primary {
  background-color: rgba(0, 76, 148, 0.06) !important;
  color: #004c94 !important;
}

.custom-button.tertiary {
  padding: 4px 20px !important;
  color: #064b8d !important;
  border: 1px solid rgba(6, 75, 141, 0.5) !important;
  background-color: transparent !important;
  margin-right: 5px;
}

.custom-button.quaternary {
  padding: 4px 20px !important;
  color: #064b8d !important;
  border: 1px solid rgba(6, 75, 141, 0.5) !important;
  background-color: transparent !important;
  margin-right: 5px;
}

.flat-button.primary {
  background-color: #004c94 !important;
  color: white;
}

.custom-button.primary-dark {
  background-color: #004c94 !important;
  color: white !important;
}

.custom-button.primary-dark.Mui-disabled {
  color: #212529 !important;
}

/* .custom-button.primary-dark > .MuiIconButton-label {
  color: white !important;
} */

.custom-button.white {
  background-color: white !important;
  color: #004c94 !important;
}

.primary-title {
  color: #004c94 !important;
  font-weight: 600;
}

.small-button {
  font-size: 11px !important;
  height: 24px;
  padding-left: 11px !important;
  padding-right: 11px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bold-button {
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.outlined {
  border-width: 1px !important;
  border-style: solid !important;
}

.outlined.primary {
  border-color: #004c94 !important;
}

.outlined.secondary {
  border-color: #fadea7 !important;
}

.empty-img {
  width: 4rem;
  height: auto;
}

.centered {
  text-align: center;
}

.narrow {
  max-width: 16rem;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2) !important;
  overflow: hidden;
}

.language-header {
  width: 100%;
  background-color: #ea862c;
  padding: 8px;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.language-header-label {
  font-weight: 400;
  font-size: 0.8rem;
  /* margin: 1rem; */
  color: white;
}

.group-option {
  font-size: 0.8rem !important;
  color: #004c94 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  min-height: 0 !important;
}

.group-option.secondary::before {
  content: "•";
  margin-left: 5px;
  margin-right: 5px;
}

.rounded {
  border-radius: 5px !important;
}

.progress-label {
  font-size: 0.9rem;
  color: gray;
  font-weight: 400;
}

.progress-label.small {
  font-size: 0.75rem;
}

.document-progress-container {
  width: 60%;
}

.form-progress-container {
  width: 30vh;
}

.simple-button {
  text-transform: none !important;
  font-weight: 400 !important;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.simple-button:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.simple-button.gray {
  color: gray !important;
}

.back-button {
  position: absolute !important;
}

.language-card {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.navbar-end-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-end-row button,
.navbar-end-row a{
  color:#CBCBCB;
  transition: color .3s ease;
}

.navbar-end-row button:hover,
.navbar-end-row a:hover {
  color: #004C94;
}

.navbar-end-row button.lang {
  font-size: 14px;
  width: 48px;
  height: 48px;
}

.navbar-end-row button.user {
  color: #004C94;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.flex {
  display: flex;
}

.MuiButton-root {
  outline: none !important;
}

.MuiIconButton-root {
  outline: none !important;
}

.custom-button:hover {
  -webkit-box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.06) !important;
  -moz-box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.06) !important;
  box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.06) !important;
}

.flat-button:hover {
  -webkit-box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.06) !important;
  -moz-box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.06) !important;
  box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.06) !important;
}

.document-form-footer {
  display: inline-flex;
  flex-direction: row;
  padding: 10px;
  background-color: rgba(0, 76, 148, 0.06);
  border-radius: 10px;
}

.light-weight {
  font-weight: 300;
  white-space: pre-wrap;
}

.group-grid-item {
  align-self: baseline;
}

.content-page {
  padding-bottom: 2rem;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  min-width: 50vw;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.fullscreen-container {
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.MuiInputBase-input {
  background-color: #f7f9fb !important;
  border-bottom: 2px solid #064b8d !important;
}

.MuiFormLabel-root {
  z-index: 1;
  pointer-events: none;
}

.MuiInput-underline:before {
  display: none !important;
}
.MuiInput-underline:after {
  display: none !important;
}

.MuiInputLabel-formControl {
  font-size: 100% !important;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0px;
  margin-left: 0px;
}

.row.centered {
  justify-content: center;
}

.document-block {
  border-radius: 5px;
  border-width: 0.5px;
  border-color: lightgray;
  border-style: solid;
  overflow: hidden;
}

.document-block-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.document-block-title {
  color: #064b8d;
  font-weight: 600;
  margin-left: -20%;
}
.document-block-title.clickable {
  cursor: pointer;
}

.document-block-flag {
  width: 20px;
  height: auto;
  object-fit: contain;
}

.document-block-language {
  color: #064b8d;
}

.document-block-separator {
  height: 20px;
  width: 1px;
  background-color: #d1d1d1;
  margin-right: 7px;
  margin-left: 7px;
}

.document-block-placeholder {
  width: 70px;
}

.document-block-docs {
  color: #064b8d;
  font-weight: 500;
}

.document-block-date {
  font-size: 10px;
  font-weight: 600;
  color: #595959;
  text-transform: uppercase;
}

.document-block-status-dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 15px;
  margin-left: 15px;
}

.document-block-status-dot.complete {
  background-color: #009448;
}

.document-block-status-dot.draft {
  background-color: #c3c2c2;
}

.document-block-status {
  color: #4a4949;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.document-block-docs-container {
  background-color: rgba(240, 244, 249, 0.26);
}

.document-block-doc-row {
  padding: 15px;
  border-top-style: solid;
  border-top-color: #dfe4eb;
  border-top-width: 1px;
}

.document-block-doc-row-buttons {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.document-block-doc-title {
  color: #292e32;
  font-size: 14px;
  font-weight: 600;
}
.sub-para {
  margin-top: 15px;
}
.document-block-doc-description {
  font-size: 12px;
  color: #292e32;
  font-weight: 500;
  margin-top: 8px;
  white-space: pre-wrap;
}

.document-block-doc-description-button {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-width: 1px;
  border-color: #c3c2c2;
  border-style: solid;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  cursor: pointer;
}

.document-block-doc-description-button-label {
  font-size: 12px;
  color: #004c94;
  pointer-events: none;
  font-weight: 600;
}

.document-block-footer {
  background-color: rgba(203, 203, 203, 0.11);
  border-top-width: 1px;
  border-top-color: #dfe4eb;
  border-top-style: solid;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.document-block-footer-label {
  font-size: 11px;
  color: #8f8f8f;
}

.question-container {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

.question-wrapper {
  max-width: 50vw;
}

.half {
  width: 50%;
}

.profile-wrapper {
  border-style: solid;
  border-width: 1px;
  border-color: #cbcbcb;
  border-radius: 4px;
}

.profile-wrapper.complete {
  background-color: #f9f9f9;
}

.profile-wrapper-header {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #cbcbcb;
}

.profile-wrapper-title {
  color: #004c94;
  font-size: 18px;
  font-weight: 600;
}

.profile-wrapper-completion {
  color: #848383;
  font-size: 14px;
  font-weight: 400;
}

.profile-wrapper-content {
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
}

.onboarding-title {
  color: #004c94;
  font-size: 24px;
  font-weight: 600;
}

.onboarding-subtitle {
  color: #004c94;
  font-size: 14px;
  font-weight: 500;
}

.onboarding-skip {
  align-self: flex-end;
  color: #888888 !important;
  font-size: 11px !important;
}

.group-block {
  border-radius: 3px !important;
}

.group-block-content {
  padding: 15px;
  margin-top: -35px;
}

.group-block-title {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
}

.group-block-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.group-block-icon-wrapper {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-left: 75%;
  margin-top: -40px;
  overflow: hidden;
  padding: 5px;
  background-color: white;
}

.group-block-icon {
  object-fit: contain;
  width: 70px;
  height: 70px;
}

.get-started-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.get-started-separator-line {
  width: 30%;
  height: 1px;
  background-color: #004c94;
}

.get-started-separator-label {
  color: #004c94;
  font-size: 24px;
  font-weight: 600;
  margin-left: 2rem;
  margin-right: 2rem;
}

.MuiButton-outlinedSizeSmall {
  max-height: 30px;
}

.group-expand-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.group-expand-button {
  color: #004c94 !important;
  font-size: 12px !important;
  font-weight: 600;
}

.flipped {
  transform: rotate(180deg);
}

.onboarding-progress-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.onboarding-progress-label {
  color: #848383;
  font-size: 14px;
  font-weight: 500;
}

/* .small-button-loader > .MuiCircularProgress-svg {
  width: 20px;
  height: 20px;
  padding: 0px;
} */

/* LEGAL PAGES */
.legal-page .title {
  color: #292e32;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: left;
}

.legal-page .title--midle {
  margin-left: 15px;
}

.legal-page .title--small {
  font-size: 1rem;
  margin-bottom: 16px !important;
  margin-top: 2rem !important;
}

.legal-page .title--big {
  font-size: 1.4rem;
  color: #004c94;
  margin-bottom: 40px !important;
}

.mg-center {
  max-width: 920px;
  margin: 20px auto;
}

.legal-page .legal-container {
  max-width: 920px;
  width: 100%;
  box-sizing: border-box;

  padding: 25px 25px;
  border-radius: 5px;
}

.legal-page .legal-container p {
  margin-bottom: 16px;
}

.legal-page .p-contain {
  padding: 20px 0;
}

.legal-page ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.legal-page .list__el {
  margin-bottom: 16px;
}

.legal-page .link-back {
  text-decoration: none;
}

.legal-page .list__el .link-back {
  display: inline-block;
}

.legal-page .link-content {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0px;
  color: #808080;
  margin-bottom: 10px;
  transition: 0.2s ease-out;
}

.legal-page .link-content:hover {
  opacity: 0.7;
}

.legal-page .link-content span:last-child {
  font-weight: 200;
  padding-left: 5px;
}

.legal-page .svg-back {
  width: 20px;
  height: 20px;
  display: block;
}

.legal-page .button-arrow {
  margin-bottom: 16px;
  background-color: transparent;
  cursor: pointer;
  border-color: transparent;
  display: flex;
  align-items: center;
}

.legal-page .button-arrow span {
  display: flex;
  align-items: center;
}

.legal-page .button-arrow span {
  display: inline-block;
}

.legal-page img {
  width: 17px;
  height: 17px;
}
.legal-page .img-arrow img {
  width: 17px;
  height: 17px;
}

.legal-page .img-arrow--rotate {
  transition: 0.1s ease-out;
  transform: rotate(90deg);
}

.legal-page .p-contain--close {
  transition: 0.2s ease-out;
  display: none;
}
.legal-page table {
  border-collapse: collapse;
}
.legal-page td {
  border: 1px solid black;
  padding: 5px;
}

.legal-page footer {
  padding-top: 20px;
}

.legal-page footer p {
  text-align: center;
  font-size: 0.6rem;
  padding-bottom: 1rem;
}

@media (min-width: 920px) {
  .mg-center {
    margin: 100px auto;
  }
  .legal-page .legal-container {
    border: 0.5px solid #d3d3d3;
  }

  .legal-page .list__el {
    margin-bottom: 50px;
  }

  .legal-page .link-content {
    margin-bottom: 40px;
  }
}

.no_partner_container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.popup-container {
  position: absolute;
  width: 500px;

  background-color: #fff;
  left: 30%;
  top: 180%;
  border-radius: 15px;
}

.popup-para {
  padding: 80px 30px 20px 30px;
  color: #333333;
}

.buttonModal-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #ea862c;
}

.buttonModal {
  position: absolute;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-image: url(./assets/cancel.svg);
  width: 20px;
  height: 20px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.popup-title {
  text-align: center;
  padding-top: 10px;
}

/* Cookies section */
.cookies-section-wrapper {
  background-color: #F7F6F6;
  box-shadow: 0 1px 10px 0 rgba(129, 129, 129, 0.36);
  padding: 22px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 500px;
  overflow-y: auto;
}

.cookies-section-wrapper .container {
  display: block;
}

.cookies-section {
  position: relative;
  padding-left: 147px;
}

.cookies-section__cookie {
  position: absolute;
  top: 24px;
  left: 0;
  max-width: 100%;
  display: block;
}

.cookies-section__top {
  margin-bottom: 17px;
}

.cookies-section__top p {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0;
  color: #020F1D;
}

.cookies-section__actions {
  margin-bottom: 5px;
}

.cookies-section__actions a,
.configure-body--cookies-section a {
  display: inline-flex;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: 27px;
  padding: 6px 16px;
  color: #064b8d;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 15px;
  transition: background-color .3s ease;
  text-decoration: none;
}

.cookies-section__actions a.blue,
.configure-body--cookies-section a.blue {
  background-color: #064b8d;
  color: #fff;
}

.cookies-section__actions a:hover {
  background-color: #f1f1f1;
}

.cookies-section__actions a.blue:hover,
.configure-body--cookies-section a.blue:hover {
  background-color: rgb(4, 52, 98);
}

.cookies-section__bottom p {
  font-size: 13px;
  color: #020F1D;
  letter-spacing: 0;
  text-align: left;
}

.cookies-section__bottom p a {
  color: #064b8d;
  font-weight: 500;
}

.cookies-section__bottom p a:hover {
  text-decoration: none;
}

.configure-body--cookies-section {
  padding: 40px 40px 20px;
}

.configure-body--cookies-section .configure {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 15px;
}

.configure-body--cookies-section a.blue {
  width: 100%;
  justify-content: center;
  padding: 10px 16px;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 767px) {
  .cookies-section {
    padding-left: 50px;
  }

  .cookies-section__cookie {
    max-width: 40px;
    top: 10px;
  }
}

/* Switch */
.switch {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  padding: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #030f1c;
  border-radius: 50px;
  background-color: #efefef;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.input-inline-block {
  max-width: 100%;
  display: inline-block;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 0px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-style: solid;
  border-width: 1px;
  border-color: #030f1c;
  border-radius: 50%;
  background-color: #fff;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input_toogle:checked+.slider {
  background-color: #064b8d;
}

.input_toogle:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.input_toogle:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.cookie__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cookie__text {
  font-size: 16px;
  font-weight: 600;
  width: 70%;
}

.cookie__text.cookie__text--light {
  color: #9f9f9f;
  font-weight: 500;
}

.configure-body {
  overflow: hidden;
}

.configure-body h5 {
  text-align: center;
  margin-bottom: 20px !important;
}

.configure__desc {
  max-width: 420px;
  font-size: 14px;
}

/* Documents menu - 17, feb 2012 update */
.documents-menu-paper {
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(207, 205, 205, 0.5) !important;
  width: 400px;
  padding: 30px 31px 33px !important;
  position: relative;
  overflow: visible;
  margin-top: 20px !important;
}

.documents-menu-paper .arrow-top {
  position: absolute;
  top: -10px;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  box-shadow: 0 0 5px 0 rgba(207, 205, 205, 0.3);
  transform: rotate(-45deg);
  background-color: #fff;
}

.documents-menu-paper .arrow-top-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background-color: #fff;
  border-radius: 10px;
}

.documents-menu__group {
  margin-bottom: 23px;
}

.documents-menu__group:last-child {
  margin-bottom: 0;
}

.documents-menu__header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.documents-menu__header img {
  display: block;
  margin-right: 7px;
}

.documents-menu__header p {
  font-size: 14px;
  color: #EA862C;
  font-weight: 600;
  margin-bottom: 0;
}

.documents-menu .language-header {
  background-color: transparent;
  padding: 0;
  margin-bottom: 5px;
}

.documents-menu .language-header span {
  color: #A8A8A8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.documents-menu li {
  font-size: 13px !important;
  line-height: 23px !important;
  font-weight: 400;
  color: #004C94 !important;
  padding: 0 !important;
  overflow: visible;
  white-space: normal;
}

/* Custom popup */
.custom-popup__inner {
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(207, 205, 205, 0.5);
  padding: 32px 30px 42px;
  position: relative;
  max-width: 400px;
}

.custom-popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FCF6F6;
  color: #DBD6D6;
  border-radius: 50%;
  cursor: pointer;
}

.custom-popup__close svg {
  font-size: 14px;
}

.custom-popup__title {
  font-size: 14px;
  color:#004C94;
  font-weight: 600;
  margin-bottom: 15px !important;
  text-align: center;
}

.custom-popup__body {
  border-top: 1px solid #F1EEEE;
  padding-top: 31px;
}

.custom-popup__body--2 {
  border-top: 0;
  padding-top: 0;
}

.custom-popup__text {
  color: #004C94;
  font-size: 13px;
  line-height: 23px;
}

/* Mobile menu */
.mobile-menu-trigger {
  display: none;
  margin-top: 15px;
  cursor: pointer;
}

.mobile-menu-wrapper {
  padding: 20px;
  max-width: 280px;
}

.mobile-menu-wrapper .custom-button {
  width: 100%;
}

@media (max-width: 991px) {
  .mobile-menu-trigger {
    display: block;
  }
  .navbar-menu-right,
  .navbar-menu-center {
    display: none;
  }
}

@media (max-width: 400px) {
  img.navbar-logo {
    width: 180px;
    height: auto;
  }
}